<template>
  <v-container>
    <v-row>
      <v-col cols="12"
             md="11">
        <h1>Sound Data Master</h1>
      </v-col>
      <v-col cols="12"
             md="1">
        <v-menu
          bottom
          left>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list rounded>
            <v-subheader>Menu</v-subheader>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in submenu"
                :key="i"
                dense
                @click="openFormSound = true">
                <v-list-item-icon>
                  <v-icon v-text="item.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <sound-list :sounds="soundList.sounds" />
    <v-dialog
      v-model="openFormSound"
      persistent
      max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Create Sound Master Data</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="card-text">
          <v-container>
            <v-text-field
              v-model="soundData.name"
              label="Sound name"
              outlined
              required />

            <v-switch
              v-model="soundData.isDefault"
              label="Default"></v-switch>

            <v-checkbox
              v-model="soundData.activated"
              class="checkboxMarginSet"
              label="Active" />
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            depressed
            color="accent"
            @click.prevent="create">
            Create
          </v-btn>
          <v-btn class="ml-5"
                 depressed
                 color="error"
                 @click="openFormSound = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'JobMasterData',
  components: {
    SoundList: () => import('@/components/MasterData/masterDataSoundList.vue'),
  },
  data() {
    return {
      submenu: [
        { text: 'Create Sound', icon: 'mdi-music-circle-outline' },
      ],
      openFormSound: false,
      soundData: {
        name: '',
        isDefault: false,
        activated: true,
      }
    };
  },
  computed: {
    ...mapGetters({
      soundList: 'masterData/GET_SOUNDS'
    })
  },
  async created() {
    await this.$store.dispatch('masterData/GET_SOUNDS_ALL');
  },
  methods: {
    async create() {
      await this.$store.dispatch('masterData/CREATE_SOUND', this.soundData);

      this.soundData.name = '';
      this.soundData.isDefault=  false;
      this.soundData.activated = true;

      this.openFormSound = false;
    }
  }
};
</script>
